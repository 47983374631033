import React from "react";
import Editor from "../assets/re.jpg";
import Property from "../assets/prop.jpg";
import Hotel from "../assets/hb.jpg";

const ProductItem = ({ image, name, content, verify }) => {
  return (
    <div
      className={`bg-white border ${
        window.innerWidth > 900 ? "w-[400px]" : "w-[300px]"
      } ${
        window.innerWidth > 1667 ? "w-[450px]" : ""
      } border-gray-200 rounded-lg shadow-xl `}
    >
      <img className="rounded-t-lg w-full bg-transparent" src={image} alt="" />
      <div className="p-5 w-full">
        <p
          className="text-[20px] mb-4"
          style={{
            fontFamily: "'Rubik', sans-serif",
            fontWeight: "500",
            lineHeight: "1em",
            textAlign: "center",
            color: "black",
            letterSpacing: 0.5,
          }}
        >
          {name}
        </p>
        <p
          className=" w-full text-[14px]"
          style={{
            fontFamily: "'Rubik', sans-serif",
            lineHeight: "1.5em",
            fontWeight: "500",
            letterSpacing: 1,
            color: "black",
            whiteSpace: "normal",
          }}
        >
          {content}
        </p>
        <a href={verify} target="_blank" rel="noopener noreferrer">
          <button className="inline-flex items-center p-2 mt-2 text-sm font-medium text-center text-white bg-[#6486B4] rounded-lg hover:bg-[#B0C9EB]">
            Visit
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </a>
      </div>
    </div>
  );
};

export const Products = () => {
  return (
    <div
      className={`bg-white  text-[16px] sm:text-[18px] text-justify w-full h-full flex justify-center items-center rounded-lg overflow-y-scroll no-scrollbar`}
    >
      <div
        className={`text-justify p-6  overflow-auto ${
          window.innerWidth > 1500 ? "max-w-[90%]" : ""
        }`}
        style={{ maxHeight: "100%" }}
      >
        <div className="p-4 text-[28px] mb-3 sm:text-[28px]">
          <p
            style={{
              fontFamily: "'Antonio', sans-serif",
              flex: "1 1 0%",
              fontWeight: "500",
              lineHeight: "1em",
              textAlign: "center",
              color: "black",
              letterSpacing: 0.5,
            }}
          >
            PRODUCTS
          </p>
        </div>
        <div
          className={` mb-3 h-full w-full p-1  flex flex-wrap  items-center justify-center ${
            window.innerWidth > 1800
              ? "gap-[60px] grid grid-cols-3"
              : " gap-[20px]"
          }`}
        >
          <ProductItem
            image={Editor}
            name="360 Editor for VR Tour Creation"
            content=" Our 360 Editor is a user-friendly tool that allows users to create
            immersive virtual tours with ease. With drag-and-drop functionality,
            users can place 360-degree photos and videos to create a seamless
            experience for viewers. The editor also includes features for adding
            interactive elements such as hotspots, informational pop-ups, and
            navigation buttons, making it easy to create engaging and
            informative virtual tours. our 360 Editor is the ultimate solution
            for creating immersive virtual tours that leave a lasting
            impression."
            verify={"https://astra3d.com/"}
          />
          <ProductItem
            image={Property}
            name="Real Estate Marketplace"
            content="Our Real Estate Marketplace is a platform that connects property
        dealers, builders, and buyers. Property sellers can easily publish
        their listings, including the virtual tours created with our 360
        Editor, making it convenient for buyers to explore properties from
        the comfort of their homes. The marketplace has features for
        scheduling property viewings, contacting sellers, and making
        inquiries. Users can book a meeting for a real-time property view
        with our 360 Video Conferencing feature, to virtually
        visit the property."
            verify={"https://propalty.com/"}
          />
          <div
            className={`bg-white border ${
              window.innerWidth > 900 ? "w-[400px]" : "w-[300px]"
            } ${
              window.innerWidth > 1667 ? "w-[450px]" : ""
            } border-gray-200 rounded-lg shadow-xl `}
          >
            <img
              className="rounded-t-lg w-full bg-transparent"
              src={Hotel}
              alt=""
            />
            <div className="p-5 w-full">
              <p
                className="text-[20px] mb-4"
                style={{
                  fontFamily: "'Rubik', sans-serif",
                  fontWeight: "500",
                  lineHeight: "1em",
                  textAlign: "center",
                  color: "black",
                  letterSpacing: 0.5,
                }}
              >
                {"Hotel Booking Platform"}
              </p>
              <p
                className=" w-full text-[14px]"
                style={{
                  fontFamily: "'Rubik', sans-serif",
                  lineHeight: "1.5em",
                  fontWeight: "500",
                  letterSpacing: 1,
                  color: "black",
                  whiteSpace: "normal",
                }}
              >
                Our Hotel Booking Platform simplifies the hotel booking process
                for travelers. Hotel owners can publish their properties on our
                platform, complete with virtual tours and detailed information
                about amenities, room types, and pricing. Users can search for
                hotels based on their preferences, view virtual tours of rooms,
                and book their stay directly through the platform. Our Book as
                per your Choice feature ensures that users can book the exact
                room they want, enhancing the booking experience and ensuring
                customer satisfaction.
              </p>

              <button className="inline-flex items-center p-2 mt-2 text-sm font-medium text-center text-white bg-[#6486B4] rounded-lg hover:bg-[#B0C9EB]">
                Coming soon
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
